/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { createContext, useContext, useEffect, useState } from 'react';
import type ResourceItem from 'interfaces/resource';
import { Permission, Token, TokenContextType } from 'contexts/resource/type';
import fetchPermissions from 'core/utils/fetchPermissions';
import { useSetLocale } from '@refinedev/core';
import fetchOrganization from 'core/utils/fetchOrg';
// eslint-disable-next-line import/no-extraneous-dependencies
import axiosInstance from 'providers/axiosInstance/axiosInstance';
// import HyperDX from '@hyperdx/browser';
// import { H } from 'highlight.run';

const OrgIDKey = 'orgId';
const programIdKey = 'programId';

const TokenContext = createContext<TokenContextType>({
  token: undefined,
  setToken: () => {},
  crudPermissions: [],
  setCrudPermission: () => {},
  modulePermission: [],
  setModulePermission: () => {},
  userDetails: {},
  setUserDetails: () => {},
  filteredResources: [],
  setFilteredResources: () => {},
  loading: false,
  setLoading: () => {},
  orgId: '',
  setOrgId: () => {},
  programId: '',
  setProgramId: () => {},
  userProgramList: [],
  organizationList: [],
  setOrganizationDropdownList: () => {},
  currentLang: '',
  setCurrentLang: () => {},
  setRoles: () => {},
  roles: [],
  socketInstance: undefined,
  setSocketInstance: () => {},
  setNotificationMessages: () => {},
  notificationMessages: [],
  setRoleName: () => {},
  roleName: '',
});

export function useTokenContext() {
  return useContext(TokenContext);
}

const TokenProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<Token>(undefined);
  const [crudPermissions, setCrudPermission] = useState<Permission>([]);
  const [modulePermission, setModulePermission] = useState<Permission>([]);
  const [filteredResources, setFilteredResources] = useState<ResourceItem[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<any>({});
  const [roles, setRoles] = useState([]);
  const [roleName, setRoleName] = useState();
  const [orgId, setOrgIdState] = useState<string | number>(() => {
    const storedOrgId = localStorage.getItem(OrgIDKey);
    return storedOrgId || '';
  });
  const [programId, setProgramIdState] = useState<string>(() => {
    const storedProgramId = localStorage.getItem(programIdKey);
    return storedProgramId || '';
  });
  const [organizationList, setOrganizationList] = useState<any[]>([]);
  const [userProgramList, setUserProgramList] = useState<any[]>([]);
  const [currentLang, setCurrentLang] = useState('en');
  const changeLanguage = useSetLocale();

  const [notificationMessages, setNotificationMessages] = useState<any>(() => {
    const notificationList = localStorage.getItem('notifications');
    return notificationList || [];
  });

  // Function to set orgId in both state and localStorage
  const setOrgId = (newOrgId: string) => {
    setOrgIdState(newOrgId);
  };

  // Function to set programId in both state and localStorage
  const setProgramId = (newProgramId: any) => {
    setProgramIdState(newProgramId);
  };

  const fetchData = async () => {
    setLoading(true);
    axiosInstance.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    await fetchOrganization(setOrganizationList);
    await fetchPermissions(
      setFilteredResources,
      setCrudPermission,
      setModulePermission,
      setUserDetails,
      changeLanguage,
      setCurrentLang,
      currentLang,
      token,
      setOrgId,
      setProgramId,
      setUserProgramList
    );
    setLoading(false);
  };

  // H.init('1jdkoe52', {
  //   serviceName: "frontend-app",
  //   tracingOrigins: true,
  //   backendUrl: '139.5.189.49:8082/public',
  //   inlineImages: false,
  //   enablePerformanceRecording: true,
  //   recordCrossOriginIframe: false,
  //   privacySetting: "none",
  //   enablePromisePatch: false,
  //   reportConsoleErrors: true,
  //   networkRecording: {
  //     enabled: true,
  //     recordHeadersAndBody: true,
  //     urlBlocklist: [
  //       // insert full or partial urls that you don't want to record here
  //       // Out of the box, Highlight will not record these URLs (they can be safely removed):
  //       "https://www.googleapis.com/identitytoolkit",
  //       "https://securetoken.googleapis.com",
  //     ],
  //   },
  // });

  // H.init('56gl9g91', {
  //   serviceName: 'frontend-app',

  //   tracingOrigins: [
  //     'localhost',
  //     'https://data-management.augment8.dev/api/vi/',
  //     'https://gateway.augment8.dev/services/user/api/v1/',
  //   ],
  //   backendUrl: 'https://highlight.augment8.dev/public ',
  //   networkRecording: {
  //     enabled: true,
  //     recordHeadersAndBody: true,
  //     urlBlocklist: [
  //       // insert full or partial urls that you don't want to record here
  //       // Out of the box, Highlight will not record these URLs (they can be safely removed):
  //       'https://www.googleapis.com/identitytoolkit',
  //       'https://securetoken.googleapis.com',
  //     ],
  //   },
  // });

  // H.identify(`${userDetails.user_mail_id}`, {
  //   id: userDetails?.unique_id,
  //   orgId: userDetails?.role_id,
  //   roleId: userDetails?.organization_id,
  //   email: userDetails?.email,
  //   name: userDetails?.name,
  //   role: userDetails?.role_name,
  // });

  // HyperDX.init({
  //   apiKey: 'c29396a5-58cb-4dab-aed1-640cc3b9d983',
  // apiKey: 'fcfe587b-d0b6-42d9-ab08-92b3bc22f44f',
  // service: 'shell-app',
  // tracePropagationTargets: [/api.myapp.domain/i], // Set to link traces from frontend to backend requests
  //   consoleCapture: true, // Capture console logs (default false)
  //   advancedNetworkCapture: true, // Capture full HTTP request/response headers and bodies (default false)
  //   url: 'http://192.168.0.135:8080'
  // });

  // HyperDX.setGlobalAttributes({
  //   userId: userDetails?.unique_id,
  //   userEmail: userDetails?.email,
  //   userName: userDetails.name,
  // });

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  return (
    <TokenContext.Provider
      value={{
        token,
        setToken,
        crudPermissions,
        setCrudPermission,
        modulePermission,
        setModulePermission,
        filteredResources,
        setFilteredResources,
        userDetails,
        setUserDetails,
        loading,
        setLoading,
        orgId,
        setOrgId,
        setProgramId,
        programId,
        userProgramList,
        organizationList,
        setOrganizationDropdownList: setOrganizationList,
        currentLang,
        setCurrentLang,
        roles,
        setRoles,
        notificationMessages,
        setNotificationMessages,
        roleName,
        setRoleName,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export default TokenProvider;
